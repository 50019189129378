import CompanyVenuesIc from '@/public/icons/ic-dashboard-company-venues.svg';
import OverviewIc from '@/public/icons/ic-dashboard-overview.svg';
import SettingsIc from '@/public/icons/ic-dashboard-settings.svg';
import SupportIc from '@/public/icons/ic-dashboard-support.svg';
import ThinkValIc from '@/public/icons/ic-dashboard-thinkval.svg';
import IcIndonesiaFlag from '@/public/icons/ic-indonesia-flag.svg';
import IcMalaysiaFlag from '@/public/icons/ic-malaysia-flag.svg';
import IcSingaporeFlag from '@/public/icons/ic-singapore-flag.svg';
import IcUkFlag from '@/public/icons/ic-uk-flag.svg';
import IcMyListing from '@/public/icons/ic-my-listing.svg';
import IcSetting from '@/public/icons/ic-settings.svg';
import IcAnalytics from '@/public/icons/ic-analytics.svg';
export const HALAL_FOOD_COUNTRIES = [
  {
    id: 1,
    name: 'Japan',
    slug: '/category/halal-food-guide/japan',
  },
  {
    id: 2,
    name: 'Hong Kong',
    slug: '/category/halal-food-guide/hong-kong',
  },
  {
    id: 3,
    name: 'Thailand',
    slug: '/category/halal-food-guide/thailand',
  },
  {
    id: 4,
    name: 'Singapore',
    slug: '/category/halal-food-guide/singapore',
  },
  {
    id: 5,
    name: 'Korea',
    slug: '/category/halal-food-guide/south-korea',
  },
  {
    id: 6,
    name: 'Europe',
    slug: '/category/halal-food-guide/europe-halal-food-guide',
  },
];
export const DESTINATIONS_COUNTRIES = [
  {
    id: 1,
    name: 'Japan',
    slug: '/category/travel-guide/japan-travel-guide',
  },
  {
    id: 2,
    name: 'Hong Kong',
    slug: '/category/travel-guide/hong-kong-travel-guide',
  },
  {
    id: 3,
    name: 'Thailand',
    slug: '/category/travel-guide/thailand-travel-guide',
  },
  {
    id: 4,
    name: 'Singapore',
    slug: '/category/travel-guide/singapore-travel-guide',
  },
  {
    id: 5,
    name: 'Korea',
    slug: '/category/travel-guide/south-korea-travel-tips',
  },
  {
    id: 6,
    name: 'Europe',
    slug: '/category/travel-guide/europe',
  },
];
export const HALAL_FOOD_ALL_CITES_SLUG = '/destination/halal-food-guide';
export const DESTINATIONS_ALL_CITES_SLUG = '/destination/travel-guide';
export const breakPointsCity = [
  { width: 1, itemsToShow: 3 },
  { width: 550, itemsToShow: 5 },
  { width: 768, itemsToShow: 8 },
  { width: 1200, itemsToShow: 8 },
];
export const breakPoints = [
  { width: 1, itemsToShow: 2 },
  { width: 550, itemsToShow: 3 },
  { width: 768, itemsToShow: 4 },
  { width: 1200, itemsToShow: 4 },
];
export const PAGE_LIMIT = {
  tags: 40,
  category: 40,
  search: 40,
};
export const FOOTER_DESTINATIONS = [
  {
    id: 1,
    name: 'Japan',
    slug: '/category/travel-guide/japan-travel-guide',
  },
  {
    id: 5,
    name: 'Korea',
    slug: '/category/travel-guide/south-korea-travel-tips',
  },
  {
    id: 2,
    name: 'Hong Kong',
    slug: '/category/travel-guide/hong-kong-travel-guide',
  },
  {
    id: 4,
    name: 'Singapore',
    slug: '/category/travel-guide/singapore-travel-guide',
  },
];
export const FOOTER_WORK_WITH_US = [
  {
    id: 1,
    name: 'Advertise',
    slug: '/page/advertise',
  },
  {
    id: 2,
    name: 'About Us',
    slug: '/page/about-us',
  },
  {
    id: 3,
    name: 'Join Us',
    slug: '/page/join-us',
  },
  {
    id: 4,
    name: 'HHWT Explorers',
    slug: '/page/contributors-form',
  },
  {
    id: 5,
    name: 'Writer Signup',
    slug: '/page/writer-signup',
  },
];
export const FOOTER_SOCIALS = [
  {
    id: 1,
    name: 'facebook',
    slug: 'https://www.facebook.com/havehalalwilltravel/',
  },
  {
    id: 2,
    name: 'instagram',
    slug: 'https://www.instagram.com/havehalalwilltravel/',
  },
  {
    id: 3,
    name: 'twitter',
    slug: 'https://twitter.com/tweethhwt/',
  },
];
export const FOOTER_HELPFUL_LINKS = [
  {
    id: 1,
    name: 'Halal Food Disclaimer',
    slug: '/page/halal-food-disclaimer',
  },
  {
    id: 2,
    name: 'Modern Muslim Traveler Report',
    slug: '/page/report',
  },
];
export const HOMEPAGE_STRUCTURE = {
  BANNER_ARTICLES: 'Banner',
  LATEST_ARTICLES: 'Latest Articles',
  RECIPES_ARTICLES: 'Recipes',
  DESTINATIONS: 'Destinations',
  HALAL_FOOD_ARTICLES: 'Halal Food Guide',
  ITINERARY_ARTICLES: 'Itinerary',
  ACCOMMODATIONS_ARTICLES: 'Accommodations',
  BUDGET_TRAVEL_ARTICLES: 'Budget Travel',
  HONEY_MOON_ARTICLES: 'Honeymoon',
  BEACH_HOLIDAY_ARTICLES: 'Beach Holidays',
};
export const LANGUAGES_SWITCHER = [
  {
    value: 'en',
    label: 'English',
    icon: IcUkFlag,
    isDisabled: false,
  },
  {
    value: 'id',
    label: 'Bahasa Indonesia',
    icon: IcIndonesiaFlag,
    isDisabled: false,
  },
  {
    value: 'ms',
    label: 'Bahasa Melayu',
    icon: IcMalaysiaFlag,
    isDisabled: false,
  },
];
export const CURRENCY_SWITCHER = [
  {
    id: 1,
    label: 'SGD',
    value: 'sgd',
  },
  {
    id: 2,
    label: 'USD',
    value: 'usd',
  },
  {
    id: 3,
    label: 'VND',
    value: 'vnd',
  },
];
export const ARTICLE_BLOCKS = {
  H2_BLOCK: 'h2_block',
  H3_BLOCK: 'h3_block',
  PARAGRAPH_BLOCK: 'paragraph_block',
  IMAGE_BLOCK: 'image_block',
  SNIPPET_BLOCK: 'venue_Widget',
  INSTAGRAM_EMBED_BLOCK: 'instagram_embed_block',
  MULTIPLE_IMAGES_BLOCK: 'multi_image_block',
  HTML_BLOCK: 'content_HTML_Block',
  ADVERTISEMENT_BLOCK: 'advertisement_block',
  BRAND_BLOCK: 'branded_placement_block',
};
export const HOMEPAGE_BLOCKS = {
  TAG: 'tag',
  CATEGORY: 'category',
  DESTINATIONS: 'destinations',
  ARTICLE: 'posts',
};
export const TRACKING_EVENT_CATEGORIES = {
  IN_ARTICLE_LISTING: 'In-Article Listing Widget',
  BRAND_PLACEMENT_BLOCK: 'Branded Placement Block',
  EXTERNAL_LINK_TRACKER: 'External Link Tracker',
};
export const TRACKING_EVENT_ACTIONS = {
  SCROLL_INTO_VIEW: 1,
  FAVOURITE_BUTTON_CLICKS: 2,
  SHARE_BUTTON_CLICKS: 3,
  SCROLL_THROUGH_IMAGES: 4,
  WIDGET_CLICKS: 5,
  LINK_ARE_CLICKED: 1,
};
export const DESTINATION_HANDBOOKS = {
  HALAL_FOOD_GUIDE: 1,
  BEST_TIME_TO_VISIT: 2,
  SAFETY_ADVISORY: 3,
  LANGUAGE_CUSTOMS: 4,
};
export const DESTINATION_HANDBOOKS_COLORS = {
  green: '#029998',
  yellow: '#BD7100',
  pink: '#AE0092',
  blue: '#0170F1',
};
export const DESTINATION_HANDBOOKS_ICON_COLORS = {
  GREEN: '#03CCCB',
  YELLOW: '#FFAD32',
  PINK: '#F30088',
  BLUE: '#6EB1FF',
};
export const DESTINATION_BLOCKS = {
  ESSENTIAL: 'essential_block',
  HANDBOOK: 'handbook_block',
  ITINERARIES: 'itineraries_block',
  FEATURED_STORIES: 'featured_stories_block',
  PRAYER_TIME: 'praytime_block',
  IMAGE: 'image_block',
  IFRAME: 'iframe_block',
};
export const DESTINATION_HANDBOOKS_LIST_COLORS = [
  {
    icon: '#03CCCB',
    color: '#029998',
    name: 'green',
  },
  {
    icon: '#FFAD32',
    color: '#BD7100',
    name: 'yellow',
  },
  {
    icon: '#F30088',
    color: '#AE0092',
    name: 'pink',
  },
  {
    icon: '#6EB1FF',
    color: '#0170F1',
    name: 'blue',
  },
];
export const REVIEW_FILTER_OPTIONS = [
  { value: '', label: 'Most Recent' },
  { value: '-rating', label: 'Highly Rated' },
  { value: '-upvote', label: 'Most Upvoted' },
];

const YEAR = new Date().getFullYear();
export const DATE_OF_VISITED = [
  { label: 'January' + ' ' + YEAR, value: 1 },
  { label: 'February' + ' ' + YEAR, value: 2 },
  { label: 'March' + ' ' + YEAR, value: 3 },
  { label: 'April' + ' ' + YEAR, value: 4 },
  { label: 'May' + ' ' + YEAR, value: 5 },
  { label: 'June' + ' ' + YEAR, value: 6 },
  { label: 'July' + ' ' + YEAR, value: 7 },
  { label: 'August' + ' ' + YEAR, value: 8 },
  { label: 'September' + ' ' + YEAR, value: 9 },
  { label: 'October' + ' ' + YEAR, value: 10 },
  { label: 'November' + ' ' + YEAR, value: 11 },
  { label: 'December' + ' ' + YEAR, value: 12 },
];

export const DASHBOARD_COMPANY = [
  {
    id: 1,
    value: 'analytics',
    name: 'Analytics',
    icon: IcAnalytics,
    slug: '/company-analytics',
  },
  {
    id: 2,
    value: 'company-dashboard',
    name: 'My Listings',
    icon: IcMyListing,
    slug: '/company-dasboard',
  },
  {
    id: 3,
    value: '',
    name: 'Setting',
    icon: IcSetting,
    slug: '',
  },
];
export const DASHBOARD_MENUS = [
  {
    id: 1,
    value: '',
    name: 'Overview',
    icon: OverviewIc,
    slug: '/overview',
  },
  {
    id: 2,
    value: 'thinkval',
    name: 'ThinkVAL Dashboard',
    icon: ThinkValIc,
    slug: '/thinkval',
  },
  {
    id: 3,
    value: 'company-venues',
    name: 'Company & Venues',
    icon: CompanyVenuesIc,
    slug: '/company-venues',
  },
  {
    id: 4,
    value: 'support',
    name: 'Support',
    icon: SupportIc,
    slug: '/support',
  },
  {
    id: 5,
    value: 'settings',
    name: 'Settings',
    icon: SettingsIc,
    slug: '/settings',
  },
];
export const DASHBOARD_COMPANY_VALUES = {
  ANALYTICS: 'analytics',
  MY_LISTINGS: 'company-dashboard',
  SETTINGS: 'setting',
};

export const DASHBOARD_MENU_VALUES = {
  OVERVIEW: '',
  THINKVAL: 'thinkval',
  COMPANY_VENUES: 'company-venues',
  SUPPORT: 'support',
  SETTINGS: 'settings',
};
export const CATEGORY = [
  {
    id: 1,
    label: 'Eat',
    value: 'eat',
  },
  {
    id: 2,
    label: 'Buy',
    value: 'buy',
  },
  {
    id: 3,
    label: 'See & Do',
    value: 'see-and-do',
  },
  {
    id: 4,
    label: 'Transport',
    value: 'transport',
  },
  {
    id: 5,
    label: 'Stay',
    value: 'stay',
  },
];

export const DAYS = [
  {
    id: 1,
    value: 0,
    key: 'monday',
    label: 'Monday',
  },
  {
    id: 2,
    value: 1,
    key: 'tuesday',
    label: 'Tuesday',
  },
  {
    id: 3,
    value: 2,
    key: 'wednesday',
    label: 'Wednesday',
  },
  {
    id: 4,
    value: 3,
    key: 'thursday',
    label: 'Thursday',
  },
  {
    id: 5,
    value: 4,
    key: 'friday',
    label: 'Friday',
  },
  {
    id: 6,
    value: 5,
    key: 'saturday',
    label: 'Saturday',
  },
  {
    id: 7,
    value: 6,
    key: 'sunday',
    label: 'Sunday',
  },
];

export const AREA_CODES = [
  {
    id: 1,
    label: 'Singapore',
    value: '+65',
    icon: IcSingaporeFlag,
  },
  {
    id: 2,
    label: 'Malaysia',
    value: '+60',
    icon: IcMalaysiaFlag,
  },
  {
    id: 3,
    label: 'Indonesia',
    value: '+62',
    icon: IcIndonesiaFlag,
  },
];

export const PRICE_CODES = [
  {
    id: 1,
    label: 'Singapore',
    value: 'SGD',
  },
  {
    id: 2,
    label: 'Malaysia',
    value: 'MYR',
  },
];
export const AUTH_METHOD = {
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  GOOGLE: 'google',
};
export const AUTH_METHOD_LIST = [
  {
    name: 'Phone Number',
    value: AUTH_METHOD.PHONE_NUMBER,
  },
  {
    name: 'Email',
    value: AUTH_METHOD.EMAIL,
  },
];
export const SIGNUP_STEP_VALUES = {
  CREATE_ACCOUNT: 1,
  ONBOARDING: 2,
  FINISH: 3,
};
export const SIGNUP_STEPS = [
  {
    name: 'Create Account',
    value: SIGNUP_STEP_VALUES.CREATE_ACCOUNT,
    slug: 'create-account',
  },
  {
    name: 'Onboarding',
    value: SIGNUP_STEP_VALUES.ONBOARDING,
    slug: 'onboarding',
  },
  {
    name: 'Finish',
    value: SIGNUP_STEP_VALUES.FINISH,
    slug: 'finish',
  },
];
export const PERSONALIZATION_QUESTIONNAIRES = {
  countryTravelBucket: 1,
  kindOfContent: 2,
  intendToTravel: 3,
  usuallyTravelWith: 4,
};

export const PROFILE_METHOD = {
  ACTIVITY: 'activity',
  SAVED: 'saved',
  REWARDS: 'rewards',
};

export const DASHBOARD_VENUES = {
  DASHBOARD: 'dashboard',
};
export const DASHBOARD_VENUS_LIST = [
  {
    name: 'Dashboard',
    value: DASHBOARD_VENUES.DASHBOARD,
  },
];

export const PROFILE_METHOD_LIST = [
  {
    name: 'Activity Feed',
    value: PROFILE_METHOD.ACTIVITY,
  },
  {
    name: 'Saved',
    value: PROFILE_METHOD.SAVED,
  },
  // {
  //   name: 'Rewards',
  //   value: PROFILE_METHOD.REWARDS,
  // },
];
export const PROFILE_SORT = [
  {
    id: 1,
    value: 'recent',
    label: 'Recent',
  },
  {
    id: 2,
    value: 'this week',
    label: 'This Week',
  },
  {
    id: 3,
    value: 'from you',
    label: 'From You',
  },
  {
    id: 4,
    value: 'from others',
    label: 'From Others',
  },
];

export const PROFILE_SETTING_METHOD = {
  BASIC: 'basic',
  FAVOURITES: 'preferences',
};

export const PROFILE_SETTING_METHOD_LIST = [
  {
    name: 'Basic information',
    value: PROFILE_SETTING_METHOD.BASIC,
  },
  {
    name: 'Preferences',
    value: PROFILE_SETTING_METHOD.FAVOURITES,
  },
];
export const websiteRegex = new RegExp(
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
);

export const websiteRegexHTTPS = new RegExp(
  /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/
);

export const SAMPLE_ROUTE = {
  ALL_COUNTRY: 'all-country',
  ALL_CITY: 'all-city',
};

export const VenueTracking = {
  WEBSITE: "link",
  PHONE: "phone",
  ACTION: "action",
  PREMIND: "premind",
  SOCIAL: "social",
  PAGE: "page",
}


export const Timezones = [
  '(GMT-12:00) Etc/GMT+12',
  '(GMT-11:00) Etc/GMT+11',
  '(GMT-11:00) Pacific/Midway',
  '(GMT-11:00) Pacific/Niue',
  '(GMT-11:00) Pacific/Pago_Pago',
  '(GMT-11:00) Pacific/Samoa',
  '(GMT-11:00) US/Samoa',
  '(GMT-10:00) America/Adak',
  '(GMT-10:00) America/Atka',
  '(GMT-10:00) Etc/GMT+10',
  '(GMT-10:00) HST',
  '(GMT-10:00) Pacific/Honolulu',
  '(GMT-10:00) Pacific/Johnston',
  '(GMT-10:00) Pacific/Rarotonga',
  '(GMT-10:00) Pacific/Tahiti',
  '(GMT-10:00) US/Aleutian',
  '(GMT-10:00) US/Hawaii',
  '(GMT-10:30) Pacific/Marquesas',
  '(GMT-9:00) America/Anchorage',
  '(GMT-9:00) America/Juneau',
  '(GMT-9:00) America/Metlakatla',
  '(GMT-9:00) America/Nome',
  '(GMT-9:00) America/Sitka',
  '(GMT-9:00) America/Yakutat',
  '(GMT-9:00) Etc/GMT+9',
  '(GMT-9:00) Pacific/Gambier',
  '(GMT-9:00) US/Alaska',
  '(GMT-8:00) America/Ensenada',
  '(GMT-8:00) America/Los_Angeles',
  '(GMT-8:00) America/Santa_Isabel',
  '(GMT-8:00) America/Tijuana',
  '(GMT-8:00) America/Vancouver',
  '(GMT-8:00) Canada/Pacific',
  '(GMT-8:00) Etc/GMT+8',
  '(GMT-8:00) Mexico/BajaNorte',
  '(GMT-8:00) PST8PDT',
  '(GMT-8:00) Pacific/Pitcairn',
  '(GMT-8:00) US/Pacific',
  '(GMT-7:00) America/Boise',
  '(GMT-7:00) America/Cambridge_Bay',
  '(GMT-7:00) America/Ciudad_Juarez',
  '(GMT-7:00) America/Creston',
  '(GMT-7:00) America/Dawson',
  '(GMT-7:00) America/Dawson_Creek',
  '(GMT-7:00) America/Denver',
  '(GMT-7:00) America/Edmonton',
  '(GMT-7:00) America/Fort_Nelson',
  '(GMT-7:00) America/Hermosillo',
  '(GMT-7:00) America/Inuvik',
  '(GMT-7:00) America/Mazatlan',
  '(GMT-7:00) America/Phoenix',
  '(GMT-7:00) America/Shiprock',
  '(GMT-7:00) America/Whitehorse',
  '(GMT-7:00) America/Yellowknife',
  '(GMT-7:00) Canada/Mountain',
  '(GMT-7:00) Canada/Yukon',
  '(GMT-7:00) Etc/GMT+7',
  '(GMT-7:00) MST',
  '(GMT-7:00) MST7MDT',
  '(GMT-7:00) Mexico/BajaSur',
  '(GMT-7:00) Navajo',
  '(GMT-7:00) US/Arizona',
  '(GMT-7:00) US/Mountain',
  '(GMT-6:00) America/Bahia_Banderas',
  '(GMT-6:00) America/Belize',
  '(GMT-6:00) America/Chicago',
  '(GMT-6:00) America/Chihuahua',
  '(GMT-6:00) America/Costa_Rica',
  '(GMT-6:00) America/El_Salvador',
  '(GMT-6:00) America/Guatemala',
  '(GMT-6:00) America/Indiana/Knox',
  '(GMT-6:00) America/Indiana/Tell_City',
  '(GMT-6:00) America/Knox_IN',
  '(GMT-6:00) America/Managua',
  '(GMT-6:00) America/Matamoros',
  '(GMT-6:00) America/Menominee',
  '(GMT-6:00) America/Merida',
  '(GMT-6:00) America/Mexico_City',
  '(GMT-6:00) America/Monterrey',
  '(GMT-6:00) America/North_Dakota/Beulah',
  '(GMT-6:00) America/North_Dakota/Center',
  '(GMT-6:00) America/North_Dakota/New_Salem',
  '(GMT-6:00) America/Ojinaga',
  '(GMT-6:00) America/Rainy_River',
  '(GMT-6:00) America/Rankin_Inlet',
  '(GMT-6:00) America/Regina',
  '(GMT-6:00) America/Resolute',
  '(GMT-6:00) America/Swift_Current',
  '(GMT-6:00) America/Tegucigalpa',
  '(GMT-6:00) America/Winnipeg',
  '(GMT-6:00) CST6CDT',
  '(GMT-6:00) Canada/Central',
  '(GMT-6:00) Canada/Saskatchewan',
  '(GMT-6:00) Etc/GMT+6',
  '(GMT-6:00) Mexico/General',
  '(GMT-6:00) Pacific/Galapagos',
  '(GMT-6:00) US/Central',
  '(GMT-6:00) US/Indiana-Starke',
  '(GMT-5:00) America/Atikokan',
  '(GMT-5:00) America/Bogota',
  '(GMT-5:00) America/Cancun',
  '(GMT-5:00) America/Cayman',
  '(GMT-5:00) America/Coral_Harbour',
  '(GMT-5:00) America/Detroit',
  '(GMT-5:00) America/Eirunepe',
  '(GMT-5:00) America/Fort_Wayne',
  '(GMT-5:00) America/Grand_Turk',
  '(GMT-5:00) America/Guayaquil',
  '(GMT-5:00) America/Havana',
  '(GMT-5:00) America/Indiana/Indianapolis',
  '(GMT-5:00) America/Indiana/Marengo',
  '(GMT-5:00) America/Indiana/Petersburg',
  '(GMT-5:00) America/Indiana/Vevay',
  '(GMT-5:00) America/Indiana/Vincennes',
  '(GMT-5:00) America/Indiana/Winamac',
  '(GMT-5:00) America/Indianapolis',
  '(GMT-5:00) America/Iqaluit',
  '(GMT-5:00) America/Jamaica',
  '(GMT-5:00) America/Kentucky/Louisville',
  '(GMT-5:00) America/Kentucky/Monticello',
  '(GMT-5:00) America/Lima',
  '(GMT-5:00) America/Louisville',
  '(GMT-5:00) America/Montreal',
  '(GMT-5:00) America/Nassau',
  '(GMT-5:00) America/New_York',
  '(GMT-5:00) America/Nipigon',
  '(GMT-5:00) America/Panama',
  '(GMT-5:00) America/Pangnirtung',
  '(GMT-5:00) America/Port-au-Prince',
  '(GMT-5:00) America/Porto_Acre',
  '(GMT-5:00) America/Rio_Branco',
  '(GMT-5:00) America/Thunder_Bay',
  '(GMT-5:00) America/Toronto',
  '(GMT-5:00) Brazil/Acre',
  '(GMT-5:00) Canada/Eastern',
  '(GMT-5:00) Chile/EasterIsland',
  '(GMT-5:00) Cuba',
  '(GMT-5:00) EST',
  '(GMT-5:00) EST5EDT',
  '(GMT-5:00) Etc/GMT+5',
  '(GMT-5:00) Jamaica',
  '(GMT-5:00) Pacific/Easter',
  '(GMT-5:00) US/East-Indiana',
  '(GMT-5:00) US/Eastern',
  '(GMT-5:00) US/Michigan',
  '(GMT-4:00) America/Anguilla',
  '(GMT-4:00) America/Antigua',
  '(GMT-4:00) America/Aruba',
  '(GMT-4:00) America/Barbados',
  '(GMT-4:00) America/Blanc-Sablon',
  '(GMT-4:00) America/Boa_Vista',
  '(GMT-4:00) America/Campo_Grande',
  '(GMT-4:00) America/Caracas',
  '(GMT-4:00) America/Cuiaba',
  '(GMT-4:00) America/Curacao',
  '(GMT-4:00) America/Dominica',
  '(GMT-4:00) America/Glace_Bay',
  '(GMT-4:00) America/Goose_Bay',
  '(GMT-4:00) America/Grenada',
  '(GMT-4:00) America/Guadeloupe',
  '(GMT-4:00) America/Guyana',
  '(GMT-4:00) America/Halifax',
  '(GMT-4:00) America/Kralendijk',
  '(GMT-4:00) America/La_Paz',
  '(GMT-4:00) America/Lower_Princes',
  '(GMT-4:00) America/Manaus',
  '(GMT-4:00) America/Marigot',
  '(GMT-4:00) America/Martinique',
  '(GMT-4:00) America/Moncton',
  '(GMT-4:00) America/Montserrat',
  '(GMT-4:00) America/Port_of_Spain',
  '(GMT-4:00) America/Porto_Velho',
  '(GMT-4:00) America/Puerto_Rico',
  '(GMT-4:00) America/Santo_Domingo',
  '(GMT-4:00) America/St_Barthelemy',
  '(GMT-4:00) America/St_Kitts',
  '(GMT-4:00) America/St_Lucia',
  '(GMT-4:00) America/St_Thomas',
  '(GMT-4:00) America/St_Vincent',
  '(GMT-4:00) America/Thule',
  '(GMT-4:00) America/Tortola',
  '(GMT-4:00) America/Virgin',
  '(GMT-4:00) Atlantic/Bermuda',
  '(GMT-4:00) Brazil/West',
  '(GMT-4:00) Canada/Atlantic',
  '(GMT-4:00) Etc/GMT+4',
  '(GMT-4:30) America/St_Johns',
  '(GMT-4:30) Canada/Newfoundland',
  '(GMT-3:00) America/Araguaina',
  '(GMT-3:00) America/Argentina/Buenos_Aires',
  '(GMT-3:00) America/Argentina/Catamarca',
  '(GMT-3:00) America/Argentina/ComodRivadavia',
  '(GMT-3:00) America/Argentina/Cordoba',
  '(GMT-3:00) America/Argentina/Jujuy',
  '(GMT-3:00) America/Argentina/La_Rioja',
  '(GMT-3:00) America/Argentina/Mendoza',
  '(GMT-3:00) America/Argentina/Rio_Gallegos',
  '(GMT-3:00) America/Argentina/Salta',
  '(GMT-3:00) America/Argentina/San_Juan',
  '(GMT-3:00) America/Argentina/San_Luis',
  '(GMT-3:00) America/Argentina/Tucuman',
  '(GMT-3:00) America/Argentina/Ushuaia',
  '(GMT-3:00) America/Asuncion',
  '(GMT-3:00) America/Bahia',
  '(GMT-3:00) America/Belem',
  '(GMT-3:00) America/Buenos_Aires',
  '(GMT-3:00) America/Catamarca',
  '(GMT-3:00) America/Cayenne',
  '(GMT-3:00) America/Cordoba',
  '(GMT-3:00) America/Fortaleza',
  '(GMT-3:00) America/Jujuy',
  '(GMT-3:00) America/Maceio',
  '(GMT-3:00) America/Mendoza',
  '(GMT-3:00) America/Miquelon',
  '(GMT-3:00) America/Montevideo',
  '(GMT-3:00) America/Paramaribo',
  '(GMT-3:00) America/Punta_Arenas',
  '(GMT-3:00) America/Recife',
  '(GMT-3:00) America/Rosario',
  '(GMT-3:00) America/Santarem',
  '(GMT-3:00) America/Santiago',
  '(GMT-3:00) America/Sao_Paulo',
  '(GMT-3:00) Antarctica/Palmer',
  '(GMT-3:00) Antarctica/Rothera',
  '(GMT-3:00) Atlantic/Stanley',
  '(GMT-3:00) Brazil/East',
  '(GMT-3:00) Chile/Continental',
  '(GMT-3:00) Etc/GMT+3',
  '(GMT-2:00) America/Godthab',
  '(GMT-2:00) America/Noronha',
  '(GMT-2:00) America/Nuuk',
  '(GMT-2:00) America/Scoresbysund',
  '(GMT-2:00) Atlantic/South_Georgia',
  '(GMT-2:00) Brazil/DeNoronha',
  '(GMT-2:00) Etc/GMT+2',
  '(GMT-1:00) Atlantic/Azores',
  '(GMT-1:00) Atlantic/Cape_Verde',
  '(GMT-1:00) Etc/GMT+1',
  '(GMT+0:00) Africa/Abidjan',
  '(GMT+0:00) Africa/Accra',
  '(GMT+0:00) Africa/Bamako',
  '(GMT+0:00) Africa/Banjul',
  '(GMT+0:00) Africa/Bissau',
  '(GMT+0:00) Africa/Conakry',
  '(GMT+0:00) Africa/Dakar',
  '(GMT+0:00) Africa/Freetown',
  '(GMT+0:00) Africa/Lome',
  '(GMT+0:00) Africa/Monrovia',
  '(GMT+0:00) Africa/Nouakchott',
  '(GMT+0:00) Africa/Ouagadougou',
  '(GMT+0:00) Africa/Sao_Tome',
  '(GMT+0:00) Africa/Timbuktu',
  '(GMT+0:00) America/Danmarkshavn',
  '(GMT+0:00) Antarctica/Troll',
  '(GMT+0:00) Atlantic/Canary',
  '(GMT+0:00) Atlantic/Faeroe',
  '(GMT+0:00) Atlantic/Faroe',
  '(GMT+0:00) Atlantic/Madeira',
  '(GMT+0:00) Atlantic/Reykjavik',
  '(GMT+0:00) Atlantic/St_Helena',
  '(GMT+0:00) Eire',
  '(GMT+0:00) Etc/GMT',
  '(GMT+0:00) Etc/GMT+0',
  '(GMT+0:00) Etc/GMT-0',
  '(GMT+0:00) Etc/GMT0',
  '(GMT+0:00) Etc/Greenwich',
  '(GMT+0:00) Etc/UCT',
  '(GMT+0:00) Etc/UTC',
  '(GMT+0:00) Etc/Universal',
  '(GMT+0:00) Etc/Zulu',
  '(GMT+0:00) Europe/Belfast',
  '(GMT+0:00) Europe/Dublin',
  '(GMT+0:00) Europe/Guernsey',
  '(GMT+0:00) Europe/Isle_of_Man',
  '(GMT+0:00) Europe/Jersey',
  '(GMT+0:00) Europe/Lisbon',
  '(GMT+0:00) Europe/London',
  '(GMT+0:00) Factory',
  '(GMT+0:00) GB',
  '(GMT+0:00) GB-Eire',
  '(GMT+0:00) GMT',
  '(GMT+0:00) GMT+0',
  '(GMT+0:00) GMT-0',
  '(GMT+0:00) GMT0',
  '(GMT+0:00) Greenwich',
  '(GMT+0:00) Iceland',
  '(GMT+0:00) Portugal',
  '(GMT+0:00) UCT',
  '(GMT+0:00) UTC',
  '(GMT+0:00) Universal',
  '(GMT+0:00) WET',
  '(GMT+0:00) Zulu',
  '(GMT+0:00) build/etc/localtime',
  '(GMT+1:00) Africa/Algiers',
  '(GMT+1:00) Africa/Bangui',
  '(GMT+1:00) Africa/Brazzaville',
  '(GMT+1:00) Africa/Casablanca',
  '(GMT+1:00) Africa/Ceuta',
  '(GMT+1:00) Africa/Douala',
  '(GMT+1:00) Africa/El_Aaiun',
  '(GMT+1:00) Africa/Kinshasa',
  '(GMT+1:00) Africa/Lagos',
  '(GMT+1:00) Africa/Libreville',
  '(GMT+1:00) Africa/Luanda',
  '(GMT+1:00) Africa/Malabo',
  '(GMT+1:00) Africa/Ndjamena',
  '(GMT+1:00) Africa/Niamey',
  '(GMT+1:00) Africa/Porto-Novo',
  '(GMT+1:00) Africa/Tunis',
  '(GMT+1:00) Arctic/Longyearbyen',
  '(GMT+1:00) Atlantic/Jan_Mayen',
  '(GMT+1:00) CET',
  '(GMT+1:00) Etc/GMT-1',
  '(GMT+1:00) Europe/Amsterdam',
  '(GMT+1:00) Europe/Andorra',
  '(GMT+1:00) Europe/Belgrade',
  '(GMT+1:00) Europe/Berlin',
  '(GMT+1:00) Europe/Bratislava',
  '(GMT+1:00) Europe/Brussels',
  '(GMT+1:00) Europe/Budapest',
  '(GMT+1:00) Europe/Busingen',
  '(GMT+1:00) Europe/Copenhagen',
  '(GMT+1:00) Europe/Gibraltar',
  '(GMT+1:00) Europe/Ljubljana',
  '(GMT+1:00) Europe/Luxembourg',
  '(GMT+1:00) Europe/Madrid',
  '(GMT+1:00) Europe/Malta',
  '(GMT+1:00) Europe/Monaco',
  '(GMT+1:00) Europe/Oslo',
  '(GMT+1:00) Europe/Paris',
  '(GMT+1:00) Europe/Podgorica',
  '(GMT+1:00) Europe/Prague',
  '(GMT+1:00) Europe/Rome',
  '(GMT+1:00) Europe/San_Marino',
  '(GMT+1:00) Europe/Sarajevo',
  '(GMT+1:00) Europe/Skopje',
  '(GMT+1:00) Europe/Stockholm',
  '(GMT+1:00) Europe/Tirane',
  '(GMT+1:00) Europe/Vaduz',
  '(GMT+1:00) Europe/Vatican',
  '(GMT+1:00) Europe/Vienna',
  '(GMT+1:00) Europe/Warsaw',
  '(GMT+1:00) Europe/Zagreb',
  '(GMT+1:00) Europe/Zurich',
  '(GMT+1:00) MET',
  '(GMT+1:00) Poland',
  '(GMT+2:00) Africa/Blantyre',
  '(GMT+2:00) Africa/Bujumbura',
  '(GMT+2:00) Africa/Cairo',
  '(GMT+2:00) Africa/Gaborone',
  '(GMT+2:00) Africa/Harare',
  '(GMT+2:00) Africa/Johannesburg',
  '(GMT+2:00) Africa/Juba',
  '(GMT+2:00) Africa/Khartoum',
  '(GMT+2:00) Africa/Kigali',
  '(GMT+2:00) Africa/Lubumbashi',
  '(GMT+2:00) Africa/Lusaka',
  '(GMT+2:00) Africa/Maputo',
  '(GMT+2:00) Africa/Maseru',
  '(GMT+2:00) Africa/Mbabane',
  '(GMT+2:00) Africa/Tripoli',
  '(GMT+2:00) Africa/Windhoek',
  '(GMT+2:00) Asia/Beirut',
  '(GMT+2:00) Asia/Famagusta',
  '(GMT+2:00) Asia/Gaza',
  '(GMT+2:00) Asia/Hebron',
  '(GMT+2:00) Asia/Jerusalem',
  '(GMT+2:00) Asia/Nicosia',
  '(GMT+2:00) Asia/Tel_Aviv',
  '(GMT+2:00) EET',
  '(GMT+2:00) Egypt',
  '(GMT+2:00) Etc/GMT-2',
  '(GMT+2:00) Europe/Athens',
  '(GMT+2:00) Europe/Bucharest',
  '(GMT+2:00) Europe/Chisinau',
  '(GMT+2:00) Europe/Helsinki',
  '(GMT+2:00) Europe/Kaliningrad',
  '(GMT+2:00) Europe/Kiev',
  '(GMT+2:00) Europe/Kyiv',
  '(GMT+2:00) Europe/Mariehamn',
  '(GMT+2:00) Europe/Nicosia',
  '(GMT+2:00) Europe/Riga',
  '(GMT+2:00) Europe/Sofia',
  '(GMT+2:00) Europe/Tallinn',
  '(GMT+2:00) Europe/Tiraspol',
  '(GMT+2:00) Europe/Uzhgorod',
  '(GMT+2:00) Europe/Vilnius',
  '(GMT+2:00) Europe/Zaporozhye',
  '(GMT+2:00) Israel',
  '(GMT+2:00) Libya',
  '(GMT+3:00) Africa/Addis_Ababa',
  '(GMT+3:00) Africa/Asmara',
  '(GMT+3:00) Africa/Asmera',
  '(GMT+3:00) Africa/Dar_es_Salaam',
  '(GMT+3:00) Africa/Djibouti',
  '(GMT+3:00) Africa/Kampala',
  '(GMT+3:00) Africa/Mogadishu',
  '(GMT+3:00) Africa/Nairobi',
  '(GMT+3:00) Antarctica/Syowa',
  '(GMT+3:00) Asia/Aden',
  '(GMT+3:00) Asia/Amman',
  '(GMT+3:00) Asia/Baghdad',
  '(GMT+3:00) Asia/Bahrain',
  '(GMT+3:00) Asia/Damascus',
  '(GMT+3:00) Asia/Istanbul',
  '(GMT+3:00) Asia/Kuwait',
  '(GMT+3:00) Asia/Qatar',
  '(GMT+3:00) Asia/Riyadh',
  '(GMT+3:00) Etc/GMT-3',
  '(GMT+3:00) Europe/Istanbul',
  '(GMT+3:00) Europe/Kirov',
  '(GMT+3:00) Europe/Minsk',
  '(GMT+3:00) Europe/Moscow',
  '(GMT+3:00) Europe/Simferopol',
  '(GMT+3:00) Europe/Volgograd',
  '(GMT+3:00) Indian/Antananarivo',
  '(GMT+3:00) Indian/Comoro',
  '(GMT+3:00) Indian/Mayotte',
  '(GMT+3:00) Turkey',
  '(GMT+3:00) W-SU',
  '(GMT+3:30) Asia/Tehran',
  '(GMT+3:30) Iran',
  '(GMT+4:00) Asia/Baku',
  '(GMT+4:00) Asia/Dubai',
  '(GMT+4:00) Asia/Muscat',
  '(GMT+4:00) Asia/Tbilisi',
  '(GMT+4:00) Asia/Yerevan',
  '(GMT+4:00) Etc/GMT-4',
  '(GMT+4:00) Europe/Astrakhan',
  '(GMT+4:00) Europe/Samara',
  '(GMT+4:00) Europe/Saratov',
  '(GMT+4:00) Europe/Ulyanovsk',
  '(GMT+4:00) Indian/Mahe',
  '(GMT+4:00) Indian/Mauritius',
  '(GMT+4:00) Indian/Reunion',
  '(GMT+4:30) Asia/Kabul',
  '(GMT+5:00) Antarctica/Mawson',
  '(GMT+5:00) Antarctica/Vostok',
  '(GMT+5:00) Asia/Almaty',
  '(GMT+5:00) Asia/Aqtau',
  '(GMT+5:00) Asia/Aqtobe',
  '(GMT+5:00) Asia/Ashgabat',
  '(GMT+5:00) Asia/Ashkhabad',
  '(GMT+5:00) Asia/Atyrau',
  '(GMT+5:00) Asia/Dushanbe',
  '(GMT+5:00) Asia/Karachi',
  '(GMT+5:00) Asia/Oral',
  '(GMT+5:00) Asia/Qostanay',
  '(GMT+5:00) Asia/Qyzylorda',
  '(GMT+5:00) Asia/Samarkand',
  '(GMT+5:00) Asia/Tashkent',
  '(GMT+5:00) Asia/Yekaterinburg',
  '(GMT+5:00) Etc/GMT-5',
  '(GMT+5:00) Indian/Kerguelen',
  '(GMT+5:00) Indian/Maldives',
  '(GMT+5:30) Asia/Calcutta',
  '(GMT+5:30) Asia/Colombo',
  '(GMT+5:30) Asia/Kolkata',
  '(GMT+5:45) Asia/Kathmandu',
  '(GMT+5:45) Asia/Katmandu',
  '(GMT+6:00) Asia/Bishkek',
  '(GMT+6:00) Asia/Dacca',
  '(GMT+6:00) Asia/Dhaka',
  '(GMT+6:00) Asia/Kashgar',
  '(GMT+6:00) Asia/Omsk',
  '(GMT+6:00) Asia/Thimbu',
  '(GMT+6:00) Asia/Thimphu',
  '(GMT+6:00) Asia/Urumqi',
  '(GMT+6:00) Etc/GMT-6',
  '(GMT+6:00) Indian/Chagos',
  '(GMT+6:30) Asia/Rangoon',
  '(GMT+6:30) Asia/Yangon',
  '(GMT+6:30) Indian/Cocos',
  '(GMT+7:00) Antarctica/Davis',
  '(GMT+7:00) Asia/Bangkok',
  '(GMT+7:00) Asia/Barnaul',
  '(GMT+7:00) Asia/Ho_Chi_Minh',
  '(GMT+7:00) Asia/Hovd',
  '(GMT+7:00) Asia/Jakarta',
  '(GMT+7:00) Asia/Krasnoyarsk',
  '(GMT+7:00) Asia/Novokuznetsk',
  '(GMT+7:00) Asia/Novosibirsk',
  '(GMT+7:00) Asia/Phnom_Penh',
  '(GMT+7:00) Asia/Pontianak',
  '(GMT+7:00) Asia/Saigon',
  '(GMT+7:00) Asia/Tomsk',
  '(GMT+7:00) Asia/Vientiane',
  '(GMT+7:00) Etc/GMT-7',
  '(GMT+7:00) Indian/Christmas',
  '(GMT+8:00) Antarctica/Casey',
  '(GMT+8:00) Asia/Brunei',
  '(GMT+8:00) Asia/Choibalsan',
  '(GMT+8:00) Asia/Chongqing',
  '(GMT+8:00) Asia/Chungking',
  '(GMT+8:00) Asia/Harbin',
  '(GMT+8:00) Asia/Hong_Kong',
  '(GMT+8:00) Asia/Irkutsk',
  '(GMT+8:00) Asia/Kuala_Lumpur',
  '(GMT+8:00) Asia/Kuching',
  '(GMT+8:00) Asia/Macao',
  '(GMT+8:00) Asia/Macau',
  '(GMT+8:00) Asia/Makassar',
  '(GMT+8:00) Asia/Manila',
  '(GMT+8:00) Asia/Shanghai',
  '(GMT+8:00) Asia/Singapore',
  '(GMT+8:00) Asia/Taipei',
  '(GMT+8:00) Asia/Ujung_Pandang',
  '(GMT+8:00) Asia/Ulaanbaatar',
  '(GMT+8:00) Asia/Ulan_Bator',
  '(GMT+8:00) Australia/Perth',
  '(GMT+8:00) Australia/West',
  '(GMT+8:00) Etc/GMT-8',
  '(GMT+8:00) Hongkong',
  '(GMT+8:00) PRC',
  '(GMT+8:00) ROC',
  '(GMT+8:00) Singapore',
  '(GMT+8:45) Australia/Eucla',
  '(GMT+9:00) Asia/Chita',
  '(GMT+9:00) Asia/Dili',
  '(GMT+9:00) Asia/Jayapura',
  '(GMT+9:00) Asia/Khandyga',
  '(GMT+9:00) Asia/Pyongyang',
  '(GMT+9:00) Asia/Seoul',
  '(GMT+9:00) Asia/Tokyo',
  '(GMT+9:00) Asia/Yakutsk',
  '(GMT+9:00) Etc/GMT-9',
  '(GMT+9:00) Japan',
  '(GMT+9:00) Pacific/Palau',
  '(GMT+9:00) ROK',
  '(GMT+9:30) Australia/Darwin',
  '(GMT+9:30) Australia/North',
  '(GMT+10:00) Antarctica/DumontDUrville',
  '(GMT+10:00) Asia/Ust-Nera',
  '(GMT+10:00) Asia/Vladivostok',
  '(GMT+10:00) Australia/Brisbane',
  '(GMT+10:00) Australia/Lindeman',
  '(GMT+10:00) Australia/Queensland',
  '(GMT+10:00) Etc/GMT-10',
  '(GMT+10:00) Pacific/Chuuk',
  '(GMT+10:00) Pacific/Guam',
  '(GMT+10:00) Pacific/Port_Moresby',
  '(GMT+10:00) Pacific/Saipan',
  '(GMT+10:00) Pacific/Truk',
  '(GMT+10:00) Pacific/Yap',
  '(GMT+10:30) Australia/Adelaide',
  '(GMT+10:30) Australia/Broken_Hill',
  '(GMT+10:30) Australia/South',
  '(GMT+10:30) Australia/Yancowinna',
  '(GMT+11:00) Antarctica/Macquarie',
  '(GMT+11:00) Asia/Magadan',
  '(GMT+11:00) Asia/Sakhalin',
  '(GMT+11:00) Asia/Srednekolymsk',
  '(GMT+11:00) Australia/ACT',
  '(GMT+11:00) Australia/Canberra',
  '(GMT+11:00) Australia/Currie',
  '(GMT+11:00) Australia/Hobart',
  '(GMT+11:00) Australia/LHI',
  '(GMT+11:00) Australia/Lord_Howe',
  '(GMT+11:00) Australia/Melbourne',
  '(GMT+11:00) Australia/NSW',
  '(GMT+11:00) Australia/Sydney',
  '(GMT+11:00) Australia/Tasmania',
  '(GMT+11:00) Australia/Victoria',
  '(GMT+11:00) Etc/GMT-11',
  '(GMT+11:00) Pacific/Bougainville',
  '(GMT+11:00) Pacific/Efate',
  '(GMT+11:00) Pacific/Guadalcanal',
  '(GMT+11:00) Pacific/Kosrae',
  '(GMT+11:00) Pacific/Noumea',
  '(GMT+11:00) Pacific/Pohnpei',
  '(GMT+11:00) Pacific/Ponape',
  '(GMT+12:00) Asia/Anadyr',
  '(GMT+12:00) Asia/Kamchatka',
  '(GMT+12:00) Etc/GMT-12',
  '(GMT+12:00) Kwajalein',
  '(GMT+12:00) Pacific/Fiji',
  '(GMT+12:00) Pacific/Funafuti',
  '(GMT+12:00) Pacific/Kwajalein',
  '(GMT+12:00) Pacific/Majuro',
  '(GMT+12:00) Pacific/Nauru',
  '(GMT+12:00) Pacific/Norfolk',
  '(GMT+12:00) Pacific/Tarawa',
  '(GMT+12:00) Pacific/Wake',
  '(GMT+12:00) Pacific/Wallis',
  '(GMT+13:00) Antarctica/McMurdo',
  '(GMT+13:00) Antarctica/South_Pole',
  '(GMT+13:00) Etc/GMT-13',
  '(GMT+13:00) NZ',
  '(GMT+13:00) Pacific/Apia',
  '(GMT+13:00) Pacific/Auckland',
  '(GMT+13:00) Pacific/Enderbury',
  '(GMT+13:00) Pacific/Fakaofo',
  '(GMT+13:00) Pacific/Kanton',
  '(GMT+13:00) Pacific/Tongatapu',
  '(GMT+13:45) NZ-CHAT',
  '(GMT+13:45) Pacific/Chatham',
  '(GMT+14:00) Etc/GMT-14',
  '(GMT+14:00) Pacific/Kiritimati',
];