import { useEffect, useRef } from 'react';

function loadGPTScript(callback) {
  if (!window.googletag || !window.googletag?.cmd) {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagservices.com/tag/js/gpt.js';
    script.onload = () => {
      window.googletag = window.googletag || { cmd: [] };
      callback();
    };
    document.head.appendChild(script);
  } else {
    callback();
  }
}

export function adContextCategory(category) {
  if (category) {
    try {
      window.googletag = window.googletag || { cmd: [] };
      if (!googletag?.cmd || typeof googletag?.cmd?.push !== 'function') return;
      googletag.cmd.push(() => {
        googletag
          .pubads()
          .setTargeting('interests', [
            ...category.split(','),
            'Halal',
            'Rooms',
            'Hotel',
            'Hotels',
            'Wudhu-friendly nail polish',
            'Wellness resorts',
            'Ultimate guide',
            'Travel strollers',
            'Travel guide',
          ])
          .setTargeting('ContextualTargeting', [
            ...category.split(','),
            'Halal',
            'Rooms',
            'Hotel',
            'Hotels',
            'Wudhu-friendly nail polish',
            'Wellness resorts',
            'Ultimate guide',
            'Travel strollers',
            'Travel guide',
          ]);
      });
    } catch (err) {
      console.error('Error in setting targeting:', err);
    }
  }
}

export function DefineAdSlot({
  id,
  dimensions,
  path,
  isCollapseEmptyDivs,
  onSlotRenderEnded,
  onStatusDisplayAds,
}) {
  const slotId = `slot-${id}`;
  const adSlots = {};
  useEffect(() => {
    console.log('Call in useEffect DefineAdSlot ', slotId);
    const sizes = dimensions?.filter((size) => {
      return Array.isArray(size) ? size[0] < window.innerWidth : true;
    });
    // Register the slot with GPT when the component is loaded.
    googletag.cmd.push(() => {
      // const path = '/92492279/Mobile_Sticky_AdSlot';
      // const path = '/92492279/Mobile_Sticky_AdSlot';
      const slot = googletag.defineSlot(path, sizes, slotId);
      console.log('SLOT created', slot);
      if (slot) {
        slot.addService(googletag.pubads());
        if (isCollapseEmptyDivs) googletag.pubads().collapseEmptyDivs();
        googletag.pubads().addEventListener('slotRenderEnded', (event) => {
          if (event.slot.getSlotElementId() === id && onSlotRenderEnded) {
            onSlotRenderEnded(event);
          }
          if (event.slot.getSlotElementId() === id && onStatusDisplayAds) {
            console.log(
              'onStatusDisplayAds CHECK - +================',
              event.isEmpty ? false : true
            );
            onStatusDisplayAds(event.isEmpty ? false : true);
          }
        });
        googletag.pubads().disableInitialLoad();
        googletag.enableServices();
        console.log('DISPLAY', slotId);
        googletag.display(slotId);
        adSlots[slotId] = slot;
      }
    });

    // Clean up the slot when the component is unloaded.
    return () => {
      googletag.cmd.push(() => {
        googletag.destroySlots([adSlots[slotId]]);
      });
    };
  }, [id, dimensions, path, isCollapseEmptyDivs, onSlotRenderEnded]);

  // Create the ad slot container.
  return <div id={slotId} data-ad="true"></div>;
}

// Reusable Ad Component
export function Ad({
  id,
  dimensions,
  path,
  isCollapseEmptyDivs,
  onSlotRenderEnded,
  onStatusDisplayAds,
}) {
  const container = useRef();

  useEffect(() => {
    console.log('call in UseEffect');
    const loadAdSlot = () => {
      if (!googletag?.cmd || typeof googletag?.cmd?.push !== 'function') return;
      const sizes = dimensions?.filter((size) => {
        return Array.isArray(size) ? size[0] < window.innerWidth : true;
      });

      googletag.cmd.push(() => {
        console.log('AD SLOT', googletag.pubads().getSlots());

        // Ensure slot is not already defined for this ID
        if (
          !googletag
            .pubads()
            .getSlots()
            .some((slot) => slot.getSlotElementId() === id)
        ) {
          const slot = googletag.defineSlot(path, sizes, id);
          console.log('SLOT created', slot);
          if (slot) {
            slot.addService(googletag.pubads()).setTargeting('ads', 'lazyload');
            if (isCollapseEmptyDivs) googletag.pubads().collapseEmptyDivs();
            googletag.pubads().enableLazyLoad();
            googletag.pubads().addEventListener('slotRenderEnded', (event) => {
              if (event.slot.getSlotElementId() === id && onSlotRenderEnded) {
                onSlotRenderEnded(event);
              }
              if (event.slot.getSlotElementId() === id && onStatusDisplayAds) {
                onStatusDisplayAds(event.isEmpty ? false : true);
              }
            });
            googletag.pubads().disableInitialLoad();
            googletag.enableServices();
            console.log('DISPLAY', id);
            googletag.display(id); // Display the ad once it's ready
          } else {
            console.error(`Failed to define slot for id: ${id}`);
          }
        } else {
          console.warn(`Slot already defined for id: ${id}`);
          // googletag.destroySlots();
        }
      });
    };

    loadGPTScript(loadAdSlot);
  }, [id, dimensions, path, isCollapseEmptyDivs, onSlotRenderEnded]);

  return <div ref={container} id={id} data-ad="true"></div>;
}

// Wide Ad Component
export function WideAd({
  id,
  dimensions,
  path,
  onSlotRequested,
  onSlotResponseReceived,
  onSlotLoad,
  onSlotRenderEnded,
}) {
  const container = useRef();

  useEffect(() => {
    const loadAdSlot = () => {
      if (!googletag?.cmd || typeof googletag?.cmd?.push !== 'function') return;
      googletag.cmd.push(() => {
        // Ensure slot is not already defined for this ID
        if (
          !googletag
            .pubads()
            .getSlots()
            .some((slot) => slot.getSlotElementId() === id)
        ) {
          const adslot = googletag
            .defineSlot(path, dimensions, id)
            ?.addService(googletag.pubads());

          if (adslot) {
            if (onSlotLoad)
              googletag
                .pubads()
                .addEventListener(
                  'slotOnload',
                  (e) => e.slot.getSlotElementId() === id && onSlotLoad(e)
                );
            if (onSlotRenderEnded)
              googletag
                .pubads()
                .addEventListener(
                  'slotRenderEnded',
                  (e) =>
                    e.slot.getSlotElementId() === id && onSlotRenderEnded(e)
                );
            if (onSlotRequested)
              googletag
                .pubads()
                .addEventListener(
                  'slotRequested',
                  (e) => e.slot.getSlotElementId() === id && onSlotRequested(e)
                );
            if (onSlotResponseReceived)
              googletag
                .pubads()
                .addEventListener(
                  'slotResponseReceived',
                  (e) =>
                    e.slot.getSlotElementId() === id &&
                    onSlotResponseReceived(e)
                );

            const mapping = googletag
              .sizeMapping()
              .addSize(
                [1100, 0],
                [
                  [1, 1],
                  [728, 90],
                  [970, 90],
                  [970, 250],
                ]
              )
              .addSize(
                [640, 0],
                [
                  [728, 90],
                  [1, 1],
                ]
              )
              .build();

            adslot.defineSizeMapping(mapping);

            googletag.pubads().collapseEmptyDivs();
            googletag.enableServices();
            googletag.display(id);
          } else {
            console.error(`Failed to define slot for id: ${id}`);
          }
        } else {
          console.warn(`Slot already defined for id: ${id}`);
        }
      });
    };

    loadGPTScript(loadAdSlot);
  }, [
    id,
    dimensions,
    path,
    onSlotRequested,
    onSlotResponseReceived,
    onSlotLoad,
    onSlotRenderEnded,
  ]);

  return <div ref={container} id={id}></div>;
}

// Normal Ad Component
export function NormalAd({
  id,
  dimensions,
  path,
  onSlotRequested,
  onSlotResponseReceived,
  onSlotLoad,
  onSlotRenderEnded,
}) {
  useEffect(() => {
    const loadAdSlot = () => {
      if (!googletag?.cmd || typeof googletag?.cmd?.push !== 'function') return;
      googletag.cmd.push(() => {
        destroySlot(id);
        const slot = googletag
          .defineSlot(path, dimensions, id)
          ?.addService(googletag.pubads());

        if (slot) {
          if (onSlotLoad)
            googletag
              .pubads()
              .addEventListener(
                'slotOnload',
                (e) => e.slot.getSlotElementId() === id && onSlotLoad(e)
              );
          if (onSlotRenderEnded)
            googletag
              .pubads()
              .addEventListener(
                'slotRenderEnded',
                (e) => e.slot.getSlotElementId() === id && onSlotRenderEnded(e)
              );
          if (onSlotRequested)
            googletag
              .pubads()
              .addEventListener(
                'slotRequested',
                (e) => e.slot.getSlotElementId() === id && onSlotRequested(e)
              );
          if (onSlotResponseReceived)
            googletag
              .pubads()
              .addEventListener(
                'slotResponseReceived',
                (e) =>
                  e.slot.getSlotElementId() === id && onSlotResponseReceived(e)
              );

          googletag.pubads().collapseEmptyDivs();
          googletag.enableServices();
          googletag.display(id);
        } else {
          console.error(`Failed to define slot for id: ${id}`);
        }
      });
    };

    loadGPTScript(loadAdSlot);
  }, [
    id,
    dimensions,
    path,
    onSlotRequested,
    onSlotResponseReceived,
    onSlotLoad,
    onSlotRenderEnded,
  ]);

  return <div id={id}></div>;
}

// Lazy-loaded Ad Component
export function AdsLazy({
  id,
  dimensions,
  path,
  isCollapseEmptyDivs,
  onSlotRenderEnded,
}) {
  const container = useRef();

  useEffect(() => {
    const loadAdSlot = () => {
      const sizes = dimensions?.filter((size) =>
        Array.isArray(size) ? size[0] < window.innerWidth : true
      );

      googletag.cmd.push(() => {
        if (!googletag?.cmd || typeof googletag?.cmd?.push !== 'function')
          return;
        // Ensure slot is not already defined for this ID
        if (
          !googletag
            .pubads()
            .getSlots()
            .some((slot) => slot.getSlotElementId() === id)
        ) {
          const slot = googletag.defineSlot(path, sizes, id);
          if (slot) {
            slot.addService(googletag.pubads()).setTargeting('ads', 'lazyload');
            googletag.pubads().enableLazyLoad();

            googletag.pubads().addEventListener('slotRenderEnded', (event) => {
              const slotId = event.slot.getSlotElementId();
              if (id === slotId && onSlotRenderEnded) {
                onSlotRenderEnded(event);
              }
            });

            if (isCollapseEmptyDivs) googletag.pubads().collapseEmptyDivs();

            googletag.pubads().disableInitialLoad();
            googletag.enableServices();
            googletag.display(id);
          } else {
            console.error(`Failed to define slot for id: ${id}`);
          }
        } else {
          console.warn(`Slot already defined for id: ${id}`);
        }
      });
    };

    loadGPTScript(loadAdSlot);
  }, [id, dimensions, path, isCollapseEmptyDivs, onSlotRenderEnded]);

  return <div ref={container} id={id} data-ad="true"></div>;
}

// Utility function to remove slots
export function removeSlots(slot) {
  if (window.googletag) {
    googletag.cmd.push(() => {
      if (slot) googletag.destroySlots([slot]);
      else googletag.destroySlots();
    });
  }
}

// Utility function to refresh slots
export function refreshSlots(slot) {
  if (window.googletag) {
    googletag.cmd.push(() => {
      if (slot) googletag.pubads().refresh([slot]);
      else googletag.pubads().refresh();
    });
  }
}

// Utility function to clear all slots
export function clearAllSlots() {
  if (window.googletag) {
    googletag.cmd.push(() => {
      googletag.pubads().clear();
    });
  }
}

function destroySlot(id) {
  if (window.googletag && window.googletag.pubads) {
    const slots = googletag
      .pubads()
      .getSlots()
      .filter((slot) => slot.getSlotElementId() === id);
    if (slots.length) {
      googletag.destroySlots(slots);
      // console.log(`Slot for div id "${id}" destroyed to avoid conflict.`);
    }
  }
}
